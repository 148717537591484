<template>
  <base-view-layout v-if="user && $i18n.locale == 'es'">
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><router-link :to="{ name: 'InvestigatorsList' }"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></router-link></li>
      <li class="breadcrumb__nav-item"><router-link :to="{ name: 'InvestigatorsList' }">{{$t('researchershow.breadcrumb')}}</router-link></li>
      <li class="breadcrumb__nav-item">{{user.first_name}} {{user.last_name}}</li>
    </template>

    <template #abstract>{{$t('researchershow.title')}}</template>
    <template #title>{{user.first_name}} {{user.last_name}}</template>

    <section class="main-section">
      <h3 class="main-section__title">{{$t('researchershow.info')}}</h3>
      <div class="box box--sm box--no-shadow">
        <table class="data">
          <tbody>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('researchershow.correo')}}</p></td>
              <td><p>{{user.email}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('researchershow.nombre')}}</p></td>
              <td><p>{{user.first_name}} {{user.last_name}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('researchershow.sexo')}}</p></td>
              <td><p>{{user.sex}}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </base-view-layout>

   <base-view-layout v-if="useren && $i18n.locale == 'en'">
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><router-link :to="{ name: 'InvestigatorsList' }"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></router-link></li>
      <li class="breadcrumb__nav-item"><router-link :to="{ name: 'InvestigatorsList' }">{{$t('researchershow.breadcrumb')}}</router-link></li>
      <li class="breadcrumb__nav-item">{{useren.first_name}} {{useren.last_name}}</li>
    </template>

    <template #abstract>{{$t('researchershow.title')}}</template>
    <template #title>{{useren.first_name}} {{useren.last_name}}</template>

    <section class="main-section">
      <h3 class="main-section__title">{{$t('researchershow.info')}}</h3>
      <div class="box box--sm box--no-shadow">
        <table class="data">
          <tbody>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('researchershow.correo')}}</p></td>
              <td><p>{{useren.email}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('researchershow.nombre')}}</p></td>
              <td><p>{{useren.first_name}} {{useren.last_name}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('researchershow.sexo')}}</p></td>
              <td><p>{{useren.sex}}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </base-view-layout>
</template>

<script>
import BaseViewLayout from '@/components/BaseViewLayout'
import sexes from '@/utils/sexes'

export default {
  name: 'InvestigatorsShow',
  data () {
    return {
      user: null,
      useren:null,
    }
  },
  components: { BaseViewLayout },
  async created () {
    const user = (await this.$axios.get(`users/${this.$route.params.userId}`)).data.user
    user.sex = (sexes.es.find(sex => sex.id === user.sex) || {}).name

    const useren = (await this.$axios.get(`users/${this.$route.params.userId}`)).data.user
    useren.sex = (sexes.en.find(sex => sex.id === useren.sex) || {}).name
    this.user = user
    this.useren = useren
  }
}
</script>
